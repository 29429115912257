<template>
  <div>
    <button
      v-if="paymentIntent"
      @click="cancelPayment()"
      class="p-2 border rounded-l-md hover:bg-gray-300 text-red border-red-400"
    >
      Cancel
    </button>

    <button
      class="p-2 border rounded-r-md hover:bg-gray-300"
      v-if="paymentIntent"
      @click="finalizePayment()"
    >
      Finalize
    </button>
    <button
      v-if="!paymentIntent"
      class="p-2 border rounded-md hover:bg-gray-300"
      @click="createPayment()"
    >
      Use handheld
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: () => {
    return {
      terminal: null,
      paymentIntent: null,
    };
  },
  props: {
    order: Object,
  },
  methods: {
    async cancelPayment() {
      try {
        const response = await axios.delete('/api/v1/creditCard', this.terminal.id);
        console.log(response);
        this.paymentIntent = null;
      } catch (error) {
        console.log(error);
      }
    },
    async finalizePayment() {
      console.info('stripeHandheld: finalizePayment');
      try {
        const response = await axios.put('/api/v1/creditCard/', this.paymentIntent);
        console.log(response.data);
        if (response.data.status === 'succeeded') {
          this.$emit('progress');
        } else {
          this.$store.commit('setError', {
            message: response.data.action.failure_message,
          });
        }
      } catch (error) {
        console.log(error);
        this.paymentIntent = null;
      }
    },
    async createPayment() {
      try {
        const response = await axios.post('/api/v1/creditCard/', this.order);
        console.log(response.data);
        this.paymentIntent = response.data.action.process_payment_intent;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchConnectionToken() {
      console.info('stripeHandheld: fetchConnectionToken');
      try {
        const response = await axios.get('/api/v1/creditCard/');
        console.log(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    unexpectedDisconnect() {
      console.info('stripeHandheld: unexpectedDisconnect');
      console.log('Disconnected from reader');
    },
    async simulate() {
      try {
        const response = await axios.post('/api/v1/creditCard/simulate', {
          reader_id: this.terminal.id,
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.terminal = StripeTerminal.create({
      onFetchConnectionToken: this.fetchConnectionToken,
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
    });
  },
};
</script>

<style></style>
