<!-- this page is used on the create orders page. -->
<template>
  <div class="">
    <div>
      <div class="sm:flex justify-between items-center">
        <h4 class="my-1">Products</h4>
        <div class="flex items-center">
          <div class="my-2 sm:my-0 w-full sm:w-auto flex items-center">
            <div class="mx-2" v-if="search">
              <ToggleButton
                :width="90"
                :value="exactMatch"
                :labels="{ checked: 'exact', unchecked: 'not exact' }"
                @change="exactMatch = !exactMatch"
                :margin="3"
              ></ToggleButton>
            </div>
            <form @submit.prevent="fetchData()" class="flex relative">
              <label for="search" class="absolute left-0 ml-1 mt-1">
                <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5 m-1" />
              </label>
              <input
                @keyup.esc="search = null"
                type="text"
                class="rounded-full pl-10 py-1 bg-gray-200 w-full"
                placeholder="Search..."
                v-model="search"
              />
              <button type="submit" class="btn-action rounded ml-6">Search</button>
            </form>
          </div>
        </div>
      </div>
      <div id="products" class="h-3/4-screen overflow-hidden">
        <div class="grid grid-cols-10 font-bold border-b">
          <div class="col-span-3">Title</div>
          <div class="col-span-2">Product Num</div>
          <div>Option 1</div>
          <div>Option 2</div>
          <div>Stock</div>
          <div>Price</div>
          <div>Add</div>
        </div>

        <div
          class="overflow-y-auto h-3/4-screen"
          v-if="variants.length > 0"
          id="list_of_products"
          :key="exactMatch"
        >
          <div
            v-for="(variant, index) in variants"
            :key="index"
            class="h-8 grid grid-cols-10 items-center"
            :class="[getBackgroundColor(index)]"
          >
            <div
              class="col-span-3 text-gray-800 text-base relative whitespace-no-wrap mr-2 tooltip cursor-pointer hover:text-primary-light"
            >
              <div
                class="overflow-x-hidden w-full searchable-field"
                :id="`title-${index}`"
                @click="addToCart(variant)"
              >
                {{ variant.title }}
              </div>
              <div
                class="tooltiptext shadow rounded text-black mx-1 flex flex-col w-64 text-left top-1 -right-1 p-1"
                v-if="variant.options[0]"
              >
                <div v-if="variant.options[0]" class="w-full overflow-x-hidden">
                  Option 1: {{ variant.options[0] }}
                </div>
                <div v-if="variant.options[1]" class="w-full overflow-x-hidden">
                  Option 2: {{ variant.options[1] }}
                </div>
                <div v-if="variant.options[2]" class="w-full overflow-x-hidden">
                  Option 3: {{ variant.options[2] }}
                </div>
                <div v-if="variant.options[3]" class="w-full overflow-x-hidden">
                  Option 4: {{ variant.options[3] }}
                </div>
                <div v-if="variant.options[4]" class="w-full overflow-x-hidden">
                  Option 5: {{ variant.options[4] }}
                </div>
              </div>
            </div>
            <div
              class="col-span-2 overflow-x-auto md:overflow-hidden whitespace-no-wrap searchable-field uppercase"
              :id="`productNum-${index}`"
            >
              {{ variant.productNum }}
            </div>
            <div class="overflow-x-auto md:overflow-hidden whitespace-no-wrap">
              {{ variant.options[0] }}
            </div>
            <div class="overflow-x-auto md:overflow-hidden whitespace-no-wrap">
              {{ variant.options[1] ? variant.options[1] : null }}
            </div>
            <div class="text-center">{{ variant.stock }}</div>
            <div class="font-medium text-green-500 relative tooltip">
              ${{ variant.price }}
              <div class="absolute right-0 text-gray-600 tooltiptext whitespace-no-wrap top-0">
                ${{ variant.cost }}
              </div>
            </div>
            <button class="rounded px-2" @click="addToCart(variant)">Add</button>
          </div>
        </div>
        <div v-else class="text-center">No Products Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  data: function () {
    return {};
  },
  props: {
    page: { type: Boolean, default: true },
    associatedAccountNumber: { type: String },
  },
  computed: {
    search: {
      set(val) {
        this.$store.commit('createOrder/setSearch', val);
      },
      get() {
        return this.$store.getters['createOrder/getSearch'];
      },
    },
    loading() {
      return this.$store.getters['createOrder/getLoading'];
    },
    variants() {
      return this.$store.getters['createOrder/getVariants'];
    },
    exactMatch: {
      get() {
        return this.$store.getters['createOrder/getExactMatch'];
      },
      set(val) {
        this.$store.commit('createOrder/setExactMatch', val);
      },
    },
  },
  watch: {},
  methods: {
    addToCart(variant) {
      this.$emit('addToCart', variant);
    },
    fetchData() {
      if (!this.associatedAccountNumber) {
        this.$store.commit('setError', {
          message:
            'This order does not have an account number, pricing for the products may be incorrect. ',
          color: 'blue',
          duration: 0,
        });
      }
      this.exactMatch = false;
      this.$store.dispatch('createOrder/getUserProducts', this.associatedAccountNumber);
      if (!this.loading) {
        setTimeout(() => {
          this.highlightText();
        }, 3000);
      }
    },
    getBackgroundColor(index) {
      if (index % 2 !== 0) return 'bg-gray-200';
      return null;
    },
    highlightText() {
      if (this.search) {
        const fields = document.getElementsByClassName('searchable-field');
        const searchRegex = new RegExp(this.search, 'ig');
        fields.forEach((el) => {
          let field = el.innerHTML.toString();
          const newText = field.replace(
            searchRegex,
            `<span class='bg-blue-200'>${this.search}</span>`
          );
          document.getElementById(el.id).innerHTML = newText;
        });
      }
    },
  },
  mounted() {},
  components: {
    ToggleButton,
  },
};
</script>
<style scoped></style>
